
import { AxiosError } from 'axios';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['finished', 'uploading', 'selected', 'deleted'],
  props: {
    media: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const uploadfile = ref();
    const store = useStore();
    const status = ref<'empty' | 'selected' | 'uploading' | 'finished' | 'error'>('empty');
    const filesize = ref(0);
    const fileuploaded = ref(0);
    const deleted = ref(false);
    const errors = ref<Array<string>>([]);
    const id = ref<number>();
    // const filename = ref('');
    const deletekey = ref<string>();

    const upload = () => {
      status.value = 'uploading';
      emit('uploading');
      store
        .dispatch('uploadFile', {
          file: uploadfile.value,
          progresshandler: (prog: any) => {
            filesize.value = prog.total;
            fileuploaded.value = prog.loaded;
          },
          media: props.media,
        })
        .then((res: any) => {
          deletekey.value = res.deletekey;
          status.value = 'finished';
          id.value = res.id;
          emit('finished', res.id);
        })
        .catch((res: AxiosError) => {
          errors.value = res.response?.data.message;
          status.value = 'error';
        });
    };

    const changeUploadfile = (event: any) => {
      emit('selected');
      status.value = 'selected';
      uploadfile.value = event.target.files[0];
    };

    const filename = computed(() => {
      return uploadfile.value.name || '';
    });

    const del = () => {
      if (deletekey.value) {
        store.dispatch('deleteFile', deletekey.value).then(() => {
          emit('deleted', id.value);
          deleted.value = true;
        });
      }
    };

    const percent = computed(() => {
      if (filesize.value && fileuploaded.value) {
        return Math.ceil((fileuploaded.value / filesize.value) * 100);
      }

      return 0;
    });
    return {
      changeUploadfile,
      upload,
      status,
      filesize,
      fileuploaded,
      percent,
      filename,
      del,
      deletekey,
      deleted,
      errors,
      id,
    };
  },
});
