<style lang="scss" scoped>
.upload {
  .btn-formular {
    width: 100%;
  }

  .filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .progress-bar {
    background-color: $blue !important;
  }
}
</style>

<template>
  <div class="upload container-fluid" v-if="!deleted">
    <div class="row inputfield align-items-center" v-if="status != 'uploading' && status != 'finished'">
      <div class="col-8">
        <input type="file" @change="changeUploadfile" />
      </div>

      <div class="col-4"><button class="btn-formular" @click="upload">Hochladen</button></div>
      <div class="col-12" v-if="status == 'error'">
        <ul>
          <li :key="error" v-for="error of errors">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>

    <div class="row uploading  align-items-center" v-if="status == 'uploading' || status == 'finished'">
      <div class="col-8">
        <div class="row ">
          <div class="col-12 filename">{{ filename }}</div>
          <div class="col-12">
            <div class="progress">
              <div class="progress-bar bg-info" role="progressbar" :style="`width: ${percent}%;`" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100">{{ (fileuploaded / 1000000).toFixed(2) }}MB / {{ (filesize / 1000000).toFixed(2) }}MB</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <button class="btn-formular" @click="del" v-if="!media && status == 'finished'">Löschen</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AxiosError } from 'axios';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['finished', 'uploading', 'selected', 'deleted'],
  props: {
    media: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const uploadfile = ref();
    const store = useStore();
    const status = ref<'empty' | 'selected' | 'uploading' | 'finished' | 'error'>('empty');
    const filesize = ref(0);
    const fileuploaded = ref(0);
    const deleted = ref(false);
    const errors = ref<Array<string>>([]);
    const id = ref<number>();
    // const filename = ref('');
    const deletekey = ref<string>();

    const upload = () => {
      status.value = 'uploading';
      emit('uploading');
      store
        .dispatch('uploadFile', {
          file: uploadfile.value,
          progresshandler: (prog: any) => {
            filesize.value = prog.total;
            fileuploaded.value = prog.loaded;
          },
          media: props.media,
        })
        .then((res: any) => {
          deletekey.value = res.deletekey;
          status.value = 'finished';
          id.value = res.id;
          emit('finished', res.id);
        })
        .catch((res: AxiosError) => {
          errors.value = res.response?.data.message;
          status.value = 'error';
        });
    };

    const changeUploadfile = (event: any) => {
      emit('selected');
      status.value = 'selected';
      uploadfile.value = event.target.files[0];
    };

    const filename = computed(() => {
      return uploadfile.value.name || '';
    });

    const del = () => {
      if (deletekey.value) {
        store.dispatch('deleteFile', deletekey.value).then(() => {
          emit('deleted', id.value);
          deleted.value = true;
        });
      }
    };

    const percent = computed(() => {
      if (filesize.value && fileuploaded.value) {
        return Math.ceil((fileuploaded.value / filesize.value) * 100);
      }

      return 0;
    });
    return {
      changeUploadfile,
      upload,
      status,
      filesize,
      fileuploaded,
      percent,
      filename,
      del,
      deletekey,
      deleted,
      errors,
      id,
    };
  },
});
</script>
