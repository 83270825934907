<style lang="scss"></style>

<template>
  <div class="multiupload">
    <Upload :key="i" v-for="i in active" @finished="onFinished" @deleted="onDeleted" @selected="onChanged" @uploading="onChanged" @error="onChanged" :media="media" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Upload from './Upload.vue';

export default defineComponent({
  components: {
    Upload,
  },
  props: {
    media: { type: Boolean, default: false },
  },
  emits: ['changed', 'finished'],
  setup(props, { emit }) {
    const active = ref(1);
    const ids = ref<Array<number>>([]);

    const onChanged = () => {
      emit('changed', ids.value);
    };

    const onFinished = (id: number) => {
      active.value++;
      ids.value.push(id);
      onChanged();
      emit('finished');
    };
    const onDeleted = (id: number) => {
      ids.value = ids.value.filter((item: number) => item != id);
      onChanged();
    };

    return {
      active,
      onChanged,
      onFinished,
      onDeleted,
    };
  },
});
</script>
